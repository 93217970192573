<template>
  <b-modal
    id="replicate-order-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('repeat_payment') }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-3">
      <template v-if="!newOrder && order">
        <div class="mb-4">
          <i class="fa fa-warning font-size-large"></i>
        </div>
        <p>Você tem certeza que deseja repetir o pagamento de valor <strong>{{ order.amount/ 100 | formatMoney(order.advertiser.currency) }}</strong> do anunciante <strong>{{ order.advertiser.name }}</strong>?</p>
        <Button
          type="button"
          class="btn btn-primary mt-4"
          @click="submit"
          :loading="loader"
        >
          {{ $t('repeat_payment') }}
        </Button>
      </template>
      <!-- ORDER DETAILS -->
      <template v-else-if="newOrder">
        <p><strong>{{ $t('payment_order_created_successfully') }}</strong></p>
        <p>
          <strong>{{ $t('advertiser') }}:</strong> {{ newOrder.advertiser.name }}<br/>
          <strong>{{ $t('amount') }}:</strong> {{ (newOrder.amount / 100) | formatMoney(newOrder.advertiser.currency) }}
        </p>
        <p>
          {{ $t('to_make_the_payment') }}, <a :href="newOrder.payment_url" class="link-block-color1" target="_blank">{{ $t('click_here') }}</a>.
        </p>
        <p>
          {{ $t('copy_and_paste_the_address_pagarme') }}
        </p>
        <p>
          <strong>{{ newOrder.payment_url }}</strong>
        </p>
      </template> <!-- END ORDER DETAILS -->
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'

export default {
  name: 'CopyOrderModal',
  props: ['order'],
  components: {
    AlertWraper,
    Button
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      newOrder: null
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.newOrder = false
    },
    /**
     * On modal shown
     */
    shown () {
    },
    /**
     * Get order
     */
    async submit () {
      this.loader = true
      try {
        const order = await OrdersService.replicateOrder(this.order.id)
        this.newOrder = order
        const notification = {
          notification: {
            type: 'success',
            content: this.$t('repeated_payment_successful')
          }
        }
        NotificationService.createGlobalSuccessNotification(notification)
        this.$emit('order-replicated', order)
        // this.$bvModal.hide('replicate-order-modal')
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
