<template>
  <div class="text-center">
    <div class="qrcode-image">
      <img :src="url" alt="" v-if="url" />
    </div>
    <div class="qrcode-text p-4 rounded-default position-relative mt-3" v-if="code">
      <div class="text-center font-size-xSmall text-color2 mb-3" v-if="copiedMessage">{{ $t('code_copied') }}</div>
      {{ code }}
      <button type="button" class="btn btn-primary btn-xs" @click="copy">{{ $t('copy') }}</button>
    </div>
  </div>
</template>

<script>

import QRCode from 'qrcode'

export default {
  name: 'PIXQRCodeCard',
  props: ['code'],
  data () {
    return {
      url: '',
      copiedMessage: false
    }
  },
  async created () {
    try {
      this.url = await QRCode.toDataURL(this.code)
    } catch (error) {
      this.url = ''
    }
  },
  methods: {
    copy () {
      this.copiedMessage = true
      navigator.clipboard.writeText(this.code)
      setTimeout(() => {
        this.copiedMessage = false
      }, 3000)
    }
  }
}
</script>

<style lang="scss" scoped>
.qrcode-image {
  img {
    max-width: 250px;
  }
}
.qrcode-text {
  background: #eee;
  word-wrap: break-word;

  button {
    position: absolute;
    top: -10px;
    right: -10px;
  }
}
</style>
