<template>
  <div>
    <form @submit.prevent="submit" class="text-start default-form">
      <!-- ADVERTISER -->
      <div class="form-group mb-3" v-if="!advertiser">
        <label class="form-label fw-bold">
          {{ $t('advertiser') }}
          <span v-b-tooltip.hover :title="$t('pixel_advertiser_tooltip')"><i class="fa fa-question-circle"></i></span>
        </label>
        <div v-if="advertisersLoader">
          <Loader
            :size="'small'"
            :align="'start'"
          />
        </div>
        <div class="custom-select" v-else>
          <select class="form-control" v-model.trim="form.advertiser_id">
            <option disabled="true" selected="true">{{ $t('select') }}</option>
            <template v-if="advertisers.length > 0">
              <option v-for="(lAdvertiser, index) in advertisers" :key="index" :value="lAdvertiser.id">{{ lAdvertiser.name }}</option>
            </template>
          </select>
        </div>
      </div> <!-- END ADVERTISER -->
      <!-- NAME -->
      <div class="form-group mb-3">
        <label class="form-label fw-bold">
          {{ $t('pixel_group_name') }}
          <span v-b-tooltip.hover :title="$t('pixel_group_name_tooltip')"><i class="fa fa-question-circle"></i></span>
        </label>
        <input type="text" class="form-control" :placeholder="$t('name')" v-model.trim="form.name" />
      </div> <!-- END NAME -->
      <!-- RULES -->
      <div>
        <h2 class="font-size-medium">{{ $t('pixel_rules') }}</h2>
        <p class="font-size-xSmall">
          <strong>{{ $t('attention') }}:</strong> {{ $t('once_created_rules_cannot_be_updated_or_deleted') }}
        </p>
        <div
          class="mb-3"
          v-for="(rule, index) in form.rules"
          :key="index"
        >
          <div class="d-flex align-items-end">
            <!-- NAME -->
            <div class="form-group col-lg-4 me-2">
              <label class="form-label fw-bold">
                {{ $t('rule_name') }}
                <span :id="`pixel-rule-name-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip
                  :target="`pixel-rule-name-tooltip-${index}`"
                  custom-class="font-size-xSmall">
                  <span v-html="$t('pixel_rule_name_tooltip')"></span>
                </b-tooltip>
              </label>
              <input
                type="text"
                class="form-control"
                :placeholder="$t('rule_name')"
                v-model.trim="form.rules[index].name"
                :disabled="form.rules[index].id"
              />
            </div> <!-- END NAME -->
            <!-- OBJECTIVE -->
            <div class="form-group col-lg-4 me-2">
              <label class="form-label fw-bold">
                {{ $t('objective') }}
                <span :id="`pixel-rule-objective-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip
                  :target="`pixel-rule-objective-tooltip-${index}`"
                  custom-class="font-size-xSmall">
                  <span v-html="$t('pixel_rule_objective_tooltip')"></span>
                </b-tooltip>
              </label>
              <div class="custom-select">
                <select
                  class="form-control"
                  v-model.trim="form.rules[index].objective"
                  :disabled="form.rules[index].id"
                >
                  <option value="" disabled>{{ $t('select') }}</option>
                  <option value="CONVERSION">{{ $t('objective_type.conversion') }}</option>
                  <option value="RETARGETING">{{ $t('objective_type.retargeting') }}</option>
                </select>
              </div>
            </div> <!-- END OBJECTIVE -->
            <div class="form-group col-lg-3">
              <!-- CONVERSION TYPE -->
              <label class="form-label fw-bold">
                {{ $t('event_label') }}
                <span :id="`pixel-rule-event-name-tooltip-${index}`"><i class="fa fa-question-circle"></i></span>
                <b-tooltip :target="`pixel-rule-event-name-tooltip-${index}`" custom-class="font-size-xSmall">
                  {{ $t('pixel_rule_event_name_tooltip') }}
                </b-tooltip>
              </label>
              <input
                type="text"
                class="form-control"
                placeholder=""
                v-model.trim="form.rules[index].event_name"
                :disabled="form.rules[index].id"
              />
              <!-- END CONVERSIOn TYPE  -->
            </div>
            <!-- DESTROY RULE -->
            <div class="ms-2" v-if="!form.rules[index].id">
              <Button
                class="btn-danger btn-xs font-size-small btn-circle p-0 mb-2"
                @click.prevent="destroyRule(index)"
                v-b-tooltip.hover :title="$t('remove_rule')"
              >
                <i class="fa fa-trash"></i>
              </Button>
            </div><!-- END DESTROY RULE -->
          </div>
          <p class="font-size-small mt-2"><strong>{{ $t('important') }}:</strong> {{ $t('event_name_cannot_spaces_or_special_characters') }}</p>
        </div>
        <div class="mt-3 text-center">
          <Button
            type="button"
            class="btn btn-color1 btn-sm"
            @click="addRule"
          >
            {{ $t('add_rule') }}
          </Button>
        </div>
      </div><!-- END RULES -->

      <!-- ACTIONS -->
      <div class="text-center mt-4">
        <!-- MESSAGES -->
        <div
          class="mb-2 alert-message-card text-color2 font-size-small px-3 py-2"
          v-if="loader"
        >
          <p
            class="mb-0"
          >
            {{ $t('this_action_may_take_a_few_minutes') }}
          </p>
        </div> <!-- END MESSAGES -->
        <Button
          type="submit"
          class="btn btn-primary"
          :loading="loader"
        >
          {{ pixel ? $t('edit') : $t('create') }}
        </Button>
      </div> <!-- END ACTIONS -->
    </form>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import PixelsService from '@/modules/pixels/services/pixels-service'
import { parseErrorResponseToArray } from '@/utils'
import { mapState } from 'vuex'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'UpsertPixelForm',
  props: ['advertiser', 'pixel'],
  components: {
    Button,
    Loader
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      loader: false,
      form: {
        advertiser_id: '',
        name: '',
        rules: []
      }
    }
  },
  created () {
    if (this.advertiser) this.form.advertiser_id = this.advertiser.id
    else this.getAdvertisers()

    if (this.pixel) {
      this.form.name = this.pixel.name
      this.pixel.rules.forEach(item => {
        this.form.rules.push({
          id: item.id,
          name: item.name,
          objective: item.objective,
          event_name: item.event_name
        })
      })
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    })
  },
  methods: {
    /**
     * Add rule
     */
    addRule () {
      this.form.rules.push({
        name: '',
        objective: '',
        event_name: ''
      })
    },
    /**
     * Destroy rule
     */
    destroyRule (index) {
      this.form.rules.splice(index, 1)
    },
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        if (this.advertiser) {
          this.advertisers = advertisers.data.filter(item => {
            return item.id === this.advertiser.id
          })
        } else {
          this.advertisers = advertisers.data
        }
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.advertisersLoader = false
    },
    /**
     * Submit
     */
    async submit () {
      this.loader = true
      try {
        let pixel = null
        if (!this.pixel) {
          pixel = await PixelsService.createPixel({
            advertiserId: this.form.advertiser_id,
            name: this.form.name,
            rules: this.form.rules
          })
        } else {
          pixel = await PixelsService.updatePixel(this.pixel.id, {
            name: this.form.name,
            rules: this.form.rules.filter(item => {
              return !item.id
            })
          })
        }
        if (this.pixel) this.$emit('pixel-updated', pixel)
        else this.$emit('pixel-created', pixel)
      } catch (error) {
        // console.log(error)
        this.$emit('upsert-pixel-error', error)
      }
      this.loader = false
    }
  }
}
</script>
