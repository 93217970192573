<template>
  <span>
    <template v-if="!loader">
      <button
        type="button"
        class="retry-btn"
        @click="retry"
        :id="`creative-${creativeId}`"
      >
        <i class="fa fa-repeat"></i>
      </button>
      <b-tooltip
        :target="`creative-${creativeId}`"
        :title="$t('error_problem_creating')"
        container="body"
      ></b-tooltip>
    </template>
    <template v-else>
      <Loader
        :size="'small'"
        :align="'start'"
      />
    </template>
  </span>
</template>

<script>

import CreativesService from '@/modules/creatives/services/creatives-service'

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'RetryCreativeCreationOnPlatformButton',
  props: ['creativeId'],
  components: {
    Loader
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    /**
     * Retry
     */
    async retry () {
      this.loader = true
      try {
        await CreativesService.retryCreationOnPlatform(this.creativeId)
        this.$emit('retry-successful')
      } catch (error) {
        this.$emit('retry-error', error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.retry-btn {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}
</style>
