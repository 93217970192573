<template>
  <b-modal
    :id="`targeting-detail-modal-${targeting.id}`"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ localTargeting.name }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="mt-4">
      <!-- DETAILS -->
      <template v-if="!isEdit">
        <h3 class="font-size-medium mb-3">{{ $t('general_data') }}</h3>
        <p class="mb-4">
          <strong>{{ $t('name') }}:</strong> {{ localTargeting.name }}<br/>
          <strong>{{ $t('advertiser') }}:</strong> {{ localTargeting.advertiser.name }}<br/>
          <strong>{{ $t('type') }}:</strong> {{ localTargeting.type | formatCustomTargetingType }}<br/>
          <strong>{{ $t('elements') }}:</strong> {{ localTargeting.value_count }}<br/>
          <strong>{{ $t('status') }}:</strong> {{ localTargeting.status | formatStatus }}
        </p>
        <h3 class="font-size-medium mb-3">{{ $t('values') }}</h3>
        <div v-if="loader && values.length === 0" class="text-center">
          <Loader
            :size="'big'"
          />
        </div>
        <ul v-else-if="values.length > 0">
          <li v-for="(value, index) in values" :key="index">
            {{ value.value }}
          </li>
        </ul>
        <div class="text-center mt-4">
          <Button
            class="btn-primary"
            @click="isEdit = true"
          >
            {{ $t('edit') }}
          </Button>
        </div>
      </template> <!-- END DETAILS -->
      <!-- EDIT -->
      <template v-else>
        <UpsertCustomTargetingForm
          :targeting="localTargeting"
          :values="valuesValues"
          :type="localTargeting.type"
          @custom-targeting-updated="handleUpdated"
          @upsert-custom-targeting-error="handleError"
        />
      </template> <!-- END EDIT -->
    </div>
  </b-modal>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import UpsertCustomTargetingForm from '@/components/custom-targetings/UpsertCustomTargetingForm'

export default {
  name: 'CustomTargetingDetailModal',
  props: ['targeting'],
  components: {
    AlertWraper,
    Button,
    Loader,
    UpsertCustomTargetingForm
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      isEdit: false,
      loader: false,
      localTargeting: '',
      values: []
    }
  },
  created () {
    this.localTargeting = this.targeting
  },
  computed: {
    valuesValues () {
      return this.values.map(item => {
        return item.value
      })
    }
  },
  methods: {
    /**
     * Get custom targeting
     */
    async getCustomTargeting () {
      this.loader = true
      try {
        const targeting = await CustomTargetingsService.getCustomTargeting(this.targeting.id)
        this.localTargeting = targeting
        this.values = targeting.values
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    },
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle updated
     */
    handleUpdated (customTargeting) {
      this.reset()
    },
    /**
     * Hidden
     */
    hidden () {
      this.values = []
      this.content = null
      this.isEdit = false
    },
    /**
     * Reset
     */
    reset () {
      this.isEdit = false
      this.values = []
      this.content = null
      this.getCustomTargeting()
    },
    /**
     * Shown
     */
    shown () {
      this.getCustomTargeting()
    }
  }
}
</script>
