import axios from '@/plugins/axios'

/**
 * Create custom targeting
 */
const createCustomTargeting = async ({
  advertiserId,
  name,
  type,
  values = [],
  file
}) => {
  try {
    const form = new FormData()
    form.append('advertiser_id', advertiserId)
    form.append('name', name)
    form.append('type', type)
    values.forEach(value => {
      form.append('values[]', value)
    })
    form.append('file', file)

    const response = await axios.post('custom-targetings', form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get custom targeting
 */
const getCustomTargeting = async (id) => {
  try {
    const response = await axios.get(`custom-targetings/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get custom targetings
 */
const getCustomTargetings = async ({
  advertiserId,
  types,
  page = 1,
  withGlobal = false
}) => {
  try {
    const params = {
      advertiser_id: advertiserId,
      types: types,
      page: page
    }
    if (withGlobal) params.with_global = 1

    const response = await axios.get('custom-targetings', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`custom-targetings/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update custom targeting
 */
const updateCustomTargeting = async (id, {
  name,
  values = [],
  file
}) => {
  try {
    const form = new FormData()
    form.append('name', name)
    values.forEach(value => {
      form.append('values[]', value)
    })
    form.append('file', file)
    form.append('_method', 'PUT')

    const response = await axios.post(`custom-targetings/${id}`, form, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createCustomTargeting,
  getCustomTargeting,
  getCustomTargetings,
  retryCreationOnPlatform,
  updateCustomTargeting
}
