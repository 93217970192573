<template>
  <b-modal
    id="order-detail-modal"
    :modal-class="'default-modal'"
    :hide-footer="true"
    @shown="shown"
    @hidden="hidden"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ $t('payment_details') }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div class="text-center mt-4" v-if="loader">
      <Loader
        :size="'big'"
      />
    </div>
    <div class="mt-4" v-else-if="order">
      <h3 class="font-size-medium">{{ $t('general_data') }}</h3>
      <p>
        <strong>{{ $t('advertiser') }}:</strong> {{ order.advertiser.name }}<br/>
        <strong>{{ $t('amount') }}:</strong> {{ order.amount / 100 | formatMoney(order.advertiser.currency) }}<br/>
        <strong>{{ $t('creation_date') }}:</strong> {{ order.created_at | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
        <strong>{{ $t('payment_url') }}:</strong> <a :href="order.payment_url" target="_blank">{{ order.payment_url }}</a><br/>
        <strong>{{ $t('payment_status2') }}:</strong> {{ order.payment_status | formatPaymentStatus }}
      </p>
      <template v-if="invoice">
        <h3 class="font-size-medium">{{ $t('payment_data') }}</h3>
        <p>
          <strong>{{ $t('payment_method2') }}:</strong> {{ invoice.payment_method | formatPaymentMethod }}<br/>
        </p>
        <template v-if="invoice.payment_method === 'BOLETO'">
          <p>
            <strong>{{ $t('boleto_barcode') }}:</strong> {{ invoice.boleto_barcode }}<br/>
            <strong>{{ $t('expiration_date') }}:</strong> {{ invoice.boleto_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}<br/>
            <strong>{{ $t('boleto_url') }}:</strong> <a :href="invoice.boleto_url" target="_blank">{{ invoice.boleto_url }}</a>
          </p>
        </template>
        <template v-else-if="invoice.payment_method === 'PIX'">
          <p><strong>{{ $t('expiration_date') }}:</strong> {{ invoice.pix_expiration_date | formatDate('DD/MM/YYYY HH:mm:ss') }}</p>
          <PIXQRCodeCard
            :code="invoice.pix_qr_code"
          />
        </template>
        <template v-else-if="invoice.payment_method === 'CREDIT_CARD'">
          <p>
            <strong>{{ $t('card_brand') }}:</strong> {{ invoice.card_brand | formatCreditCardBrand }}<br/>
            <strong>{{ $t('card_last_digits') }}:</strong> {{ invoice.card_last_digits }}
          </p>
        </template>
      </template>
    </div>
  </b-modal>
</template>

<script>

import OrdersService from '@/modules/orders/services/orders-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import Loader from '@/components/common/Loader/Loader'
import PIXQRCodeCard from '@/components/common/QRCode/PIXQRCodeCard'

export default {
  name: 'OrderDetailModal',
  props: ['orderId'],
  components: {
    AlertWraper,
    Loader,
    PIXQRCodeCard
  },
  data () {
    return {
      content: null,
      content_type: 'error',
      loader: false,
      order: null
    }
  },
  computed: {
    invoice () {
      return this.order && this.order.invoices.length > 0 ? this.order.invoices[0] : null
    }
  },
  methods: {
    /**
     * On modal hidden
     */
    hidden () {
      this.order = null
    },
    /**
     * On modal shown
     */
    shown () {
      this.getOrder()
    },
    /**
     * Get order
     */
    async getOrder () {
      this.loader = true
      try {
        const order = await OrdersService.getOrder(this.orderId)
        this.order = order
      } catch (error) {
        this.content = parseErrorResponseToArray(error)
      }
      this.loader = false
    }
  }
}
</script>
