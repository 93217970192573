<template>
  <b-modal
    id="upsert-pixel-modal"
    :modal-class="'default-modal modal-size-md'"
    :hide-footer="true"
    @hidden="reset"
    @shown="shown"
  >
    <div class="text-center">
      <h2 class="highlighted-color1">
        <span>{{ title }}</span>
      </h2>
    </div>
    <AlertWraper
      :content="content"
      :type="content_type"
    />
    <div>
      <UpsertPixelForm
        :advertiser="advertiser"
        :pixel="pixel"
        @pixel-created="handleSubmit"
        @pixel-updated="handleSubmit"
        @upsert-pixel-error="handleError"
      />
    </div>
  </b-modal>
</template>

<script>

import NotificationService from '@/modules/notifications/services/notifications-service'
import { parseErrorResponseToArray } from '@/utils'

import AlertWraper from '@/components/common/Alert/AlertWraper'
import UpsertPixelForm from '@/components/pixels/UpsertPixelForm'

export default {
  name: 'UpsertCustomTargetingModal',
  props: ['advertiser', 'pixel'],
  components: {
    AlertWraper,
    UpsertPixelForm
  },
  data () {
    return {
      content: null,
      content_type: 'error'
    }
  },
  computed: {
    title () {
      return this.pixel ? this.$t('edit_pixel') : this.$t('create_pixel')
    }
  },
  methods: {
    /**
     * Handle error
     */
    handleError (error) {
      this.content = parseErrorResponseToArray(error)
    },
    /**
     * Handle submit
     */
    handleSubmit (pixel) {
      const notification = {
        notification: {
          type: 'success',
          content: this.pixel ? this.$t('pixel_edited_successfully') : this.$t('pixel_created_successfully')
        }
      }
      NotificationService.createGlobalSuccessNotification(notification)
      this.$bvModal.hide('upsert-pixel-modal')
      if (this.pixel) this.$emit('pixel-updated', pixel)
      else this.$emit('pixel-created', pixel)
    },
    reset () {},
    /**
     * On modal shown
     */
    shown () {}
  }
}
</script>
