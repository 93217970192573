<template>
  <div class="pixel-list-table">
    <div v-if="loader && localPixels.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else>
      <!-- CARD -->
      <div class="card">
        <div class="card-body">
          <div class="table-responsive">
            <table class="table table-striped">
              <thead>
                <tr>
                  <th v-if="!advertiserId">{{ $t('advertiser') }}</th>
                  <th>{{ $t('name') }}</th>
                  <th>{{ $t('creation_date') }}</th>
                  <th v-if="1 == 2">{{ $t('number_rules') }}</th>
                  <th v-if="1 == 2">{{ $t('status') }}</th>
                  <th width="100">
                    {{ $t('rules_and_code') }}
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr class="text-nowrap" v-for="(pixel, index) in localPixels" :key="index">
                  <td v-if="!advertiserId">
                    <router-link :to="{ name: 'advertisers.show', params: { id: pixel.advertiser.id } }" class="link-color3">
                      {{ pixel.advertiser.name }}
                    </router-link>
                  </td>
                  <td>
                    <router-link :to="{ name: 'pixels.show', params: { id: pixel.id } }" class="link-color3">
                      {{ pixel.name }}
                    </router-link>
                  </td>
                  <td>{{ pixel.created_at | formatDate }}</td>
                  <td v-if="1 == 2">{{ pixel.rules.length }}</td>
                  <td v-if="1 == 2">
                    {{ pixel.status | formatStatus }}
                    <template v-if="pixel.status === 'ERROR'">
                      <RetryPixelCreationOnPlatformButton
                        :pixelId="pixel.id"
                        @retry-successful="reset"
                        @retry-error="handleRetryError"
                      />
                    </template>
                  </td>
                  <td>
                    <router-link :to="{ name: 'pixels.show', params: { id: pixel.id } }" class="link-color2" v-b-tooltip.hover :title="$t('see_more')">
                      <i class="fa fa-eye"></i>
                    </router-link>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-center mt-4">
            <Button
              class="btn-primary btn-sm"
              :loading="loader"
              @click="loadMore"
              v-if="isLoadMoreVisible"
            >
              {{ $t('load_more') }}
            </Button>
          </div>
        </div>
      </div> <!-- END CARD -->
    </template>
  </div>
</template>

<script>

import PixelsService from '@/modules/pixels/services/pixels-service'

import Button from '@/components/common/Button/Button'
import Loader from '@/components/common/Loader/Loader'
import RetryPixelCreationOnPlatformButton from '@/components/pixels/RetryPixelCreationOnPlatformButton'

export default {
  name: 'PixelListTable',
  props: ['advertiserId', 'campaignId', 'pixels', 'reload'],
  components: {
    Button,
    Loader,
    RetryPixelCreationOnPlatformButton
  },
  data () {
    return {
      localPixels: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.pixels) {
      this.localPixels = this.pixels
      this.isLoadMoreVisible = false
    } else {
      this.getPixels()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get campaigns
     */
    async getPixels () {
      // if (!this.advertiserId && !this.campaignId) return false
      this.loader = true
      try {
        const pixels = await PixelsService.getPixels({
          advertiserId: this.advertiserId,
          campaignId: this.campaignId,
          page: this.page
        })
        this.localPixels = [...this.localPixels, ...pixels.data]
        this.isLoadMoreVisible = pixels.meta.current_page !== pixels.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more campaigns
     */
    loadMore () {
      this.page++
      this.getPixels()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localPixels = []
      this.getPixels()
    }
  }
}
</script>

<style lang="scss" scoped>
a.pending {
  opacity: 0.5;
}
</style>
