import axios from '@/plugins/axios'

/**
 * Create pixel
 */
const createPixel = async ({
  advertiserId,
  name,
  rules
}) => {
  try {
    const response = await axios.post('pixels', {
      advertiser_id: advertiserId,
      name: name,
      rules: rules
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get pixel
 */
const getPixel = async (id) => {
  try {
    const response = await axios.get(`pixels/${id}`)
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Get pixels
 */
const getPixels = async ({
  advertiserId = null,
  campaignId = null,
  page = 1
}) => {
  try {
    const params = {
      page: page
    }
    if (advertiserId) params.advertiser_id = advertiserId
    if (campaignId) params.campaign_id = campaignId
    const response = await axios.get('pixels', {
      params: params
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Retry creation on platform
 */
const retryCreationOnPlatform = async id => {
  try {
    const response = await axios.post(`pixels/${id}/retry`, {
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

/**
 * Update pixel
 */
const updatePixel = async (id, {
  name,
  rules
}) => {
  try {
    const response = await axios.post(`pixels/${id}`, {
      name: name,
      rules: rules,
      _method: 'PUT'
    })
    const { data } = response
    return data
  } catch (error) {
    return Promise.reject(error)
  }
}

export default {
  createPixel,
  getPixel,
  getPixels,
  retryCreationOnPlatform,
  updatePixel
}
