<template>
  <div class="custom-targeting-list-table">
    <div v-if="loader && localTargetings.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <!-- CARD -->
    <div class="card" v-else>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped">
            <thead>
              <tr>
                <th>{{ $t('name') }}</th>
                <th>{{ $t('type') }}</th>
                <th>{{ $t('elements') }}</th>
                <th>{{ $t('status') }}</th>
                <th>{{ $t('details') }}</th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(targeting, index) in localTargetings"
                :key="index"
                class="text-nowrap"
              >
                <td>
                  <a
                    href="#"
                    class="link-color3"
                    v-b-modal="`targeting-detail-modal-${targeting.id}`"
                    @click.prevent
                  >
                    {{ targeting.name }}
                  </a>
                </td>
                <td>{{ targeting.type | formatCustomTargetingType }}</td>
                <td>{{ targeting.value_count }}</td>
                <td>
                  {{ targeting.status | formatStatus }}
                  <template v-if="targeting.status === 'ERROR'">
                    <RetryCustomTargetingCreationOnPlatform
                      :targetingId="targeting.id"
                      @retry-successful="reset"
                      @retry-error="handleRetryError"
                    />
                  </template>
                </td>
                <td>
                  <a
                    href="#"
                    class="link-color2"
                    v-b-tooltip.hover :title="$t('see_more')"
                    v-b-modal="`targeting-detail-modal-${targeting.id}`"
                    @click.prevent
                  >
                    <i class="fa fa-eye"></i>
                  </a>
                  <CustomTargetingDetailModal
                    :targeting="targeting"
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="text-center mt-4">
          <Button
            class="btn-primary btn-sm"
            :loading="loader"
            @click="loadMore"
            v-if="isLoadMoreVisible"
          >
            {{ $t('load_more') }}
          </Button>
        </div>
      </div> <!-- END CARD -->
    </div>
  </div>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'

import Button from '@/components/common/Button/Button'
import CustomTargetingDetailModal from '@/components/custom-targetings/CustomTargetingDetailModal'
// import CreativeDetailModal from '@/components/creatives/CreativeDetailModal'
import Loader from '@/components/common/Loader/Loader'
import RetryCustomTargetingCreationOnPlatform from '@/components/custom-targetings/RetryCustomTargetingCreationOnPlatform'

export default {
  name: 'CreativeListTable',
  props: ['advertiserId', 'targetings', 'reload', 'types'],
  components: {
    Button,
    CustomTargetingDetailModal,
    // CreativeDetailModal,
    Loader,
    RetryCustomTargetingCreationOnPlatform
  },
  data () {
    return {
      localTargetings: [],
      loader: false,
      page: 1,
      isLoadMoreVisible: true
    }
  },
  created () {
    if (this.targetings) {
      this.localTargetings = this.targetings
      this.isLoadMoreVisible = false
    } else {
      this.getTargetings()
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    }
  },
  methods: {
    /**
     * Get targetings
     */
    async getTargetings () {
      if (!this.advertiserId) return false

      this.loader = true
      try {
        const targetings = await CustomTargetingsService.getCustomTargetings({
          advertiserId: this.advertiserId,
          types: this.types,
          page: this.page
        })
        this.localTargetings = [...this.localTargetings, ...targetings.data]
        this.isLoadMoreVisible = targetings.meta.current_page !== targetings.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Handle retry error
     */
    handleRetryError (error) {
      error.handleGlobally && error.handleGlobally()
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getTargetings()
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localTargetings = []
      this.getTargetings()
    }
  }
}
</script>
