<template>
  <div class="orders-list-table">
    <div v-if="loader && localOrders.length === 0" class="text-center">
      <Loader
        :size="'big'"
      />
    </div>
    <template v-else-if="user">
      <!-- FILTER -->
      <section class="mb-4 d-flex align-items-center default-form">
        <div class="me-3" v-if="!advertiser">
          <span class="fw-bold form-label me-3">{{ $t('advertiser') }}:</span><br/>
          <template v-if="advertisersLoader">
            <Loader
              :size="'small'"
              :align="'start'"
            />
          </template>
          <div class="custom-select custom-select-sm" v-else>
            <select
              v-model="filters.advertiser_id"
              class="form-control form-control-sm"
            >
              <option value="">{{ $t('all') }}</option>
              <option :value="advertiser.id" v-for="(advertiser, index) in advertisers" :key="index">
                {{ advertiser.name }}
              </option>
            </select>
          </div>
        </div>
        <div class="me-3">
          <span class="fw-bold form-label me-3">{{ $t('status') }}:</span><br/>
          <div class="custom-select custom-select-sm">
            <select
              v-model="filters.status"
              class="form-control form-control-sm"
            >
              <option value="">{{ $t('all') }}</option>
              <option
                :value="status.key"
                v-for="(status, index) in filterPaymentStatus"
                :key="index"
              >
                {{ status.value }}
              </option>
            </select>
          </div>
        </div>
        <div class="d-flex text-center align-items-center justify-content-center font-size-small me-3">
          {{ $t('period_from') }}
          <DateTimeInput
            v-model.trim="filters.start"
            format="dd/MM/yyyy"
            type="date"
            theme="basic"
            class="mx-2 form-control-sm-wrapper"
          />
          {{ $t('to') }}
          <DateTimeInput
            v-model.trim="filters.end"
            format="dd/MM/yyyy"
            type="date"
            theme="basic"
            class="mx-2 form-control-sm-wrapper"
          />
        </div>
        <div>
          <Button
            class="btn-primary btn-xs"
            @click="reset"
          >
            {{ $t('search') }}
          </Button>
        </div>
      </section> <!-- END FILTER -->
      <!-- CONTENT -->
      <section>
        <template v-if="!advertiser">
          <p class="text-center" v-if="user.company.type === 'AGENCY'"><strong>{{ $t('list_payments_all_advertisers') }}</strong></p>
          <p class="text-center" v-else-if="user.company.type === 'ADVERTISER'"><strong>{{ $t('list_all_your_payments') }}</strong></p>
        </template>
        <div class="card mt-4">
          <div class="card-body">
            <div class="table-responsive">
              <table class="table table-striped">
                <thead>
                  <tr>
                    <th>{{ $t('date') }}</th>
                    <th v-if="user.company.type === 'AGENCY' && !advertiser">{{ $t('advertiser') }}</th>
                    <th>{{ $t('amount') }}</th>
                    <th>{{ $t('payment_status2') }}</th>
                    <th>{{ $t('payment_link') }}</th>
                    <th>{{ $t('invoice') }}</th>
                    <th width="100">
                      {{ $t('details') }}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="(order, index) in localOrders" :key="index">
                    <td>{{ order.created_at | formatDate }}</td>
                    <td v-if="user.company.type === 'AGENCY' && !advertiser">
                      <router-link :to="{ name: 'advertisers.show', params: { id: order.advertiser.id } }" class="link-color3">
                        {{ order.advertiser.name }}
                      </router-link>
                    </td>
                    <td>{{ order.amount / 100 | formatMoney(order.advertiser.currency) }}</td>
                    <td>{{ order.payment_status | formatPaymentStatus }}</td>
                    <td>
                      <a :href="order.payment_url" target="_blank">{{ $t('click_here') }}</a>
                    </td>
                    <td>
                      <template v-if="order.nfse_status === 'ISSUED'">
                        <router-link :to="{ name: 'orders.nfse.show', params: { id: order.id } }" target="_blank" class="link-color3">{{ $t('download') }}</router-link>
                      </template>
                      <template v-else-if="!order.nfse_status">
                        ---
                      </template>
                      <template v-else>
                        {{ order.nfse_status | formatNFSeStatus }}
                        <template v-if="order.nfse_status === 'WAITING' && !order.insertion_order">
                          <span
                            v-b-tooltip.hover
                            :title="$t('invoice_issued_30_days_after_payment')"
                            class="font-size-small"
                          >
                            <i class="fa fa-question-circle"></i>
                          </span>
                          <a href="#" @click.prevent="openIssueNFSeModal(order)" class="link-color3 ms-2" v-b-tooltip.hover :title="$t('issue_invoice')">
                            <i class="fa fa-upload"></i>
                          </a>
                        </template>
                      </template>
                    </td>
                    <td>
                      <a href="#" @click.prevent="openOrderDetailModal(order)" class="link-color3 me-2" v-b-tooltip.hover :title="$t('see_more')">
                        <i class="fa fa-eye"></i>
                      </a>
                      <a href="#" @click.prevent="openReplicateOrderModal(order)" class="link-color3" v-b-tooltip.hover :title="$t('repeat_payment')" v-if="!order.insertion_order">
                        <i class="fa fa-copy"></i>
                      </a>
                      <a href="#" @click.prevent="openDestroyOrderModal(order)" class="link-color3 ms-2" v-b-tooltip.hover :title="$t('delete_payment')" v-if="order.payment_status === 'PENDING' && !order.insertion_order">
                        <i class="fa fa-trash"></i>
                      </a>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <div class="text-center mt-4">
          <Button
            class="btn-primary btn-sm"
            :loading="loader"
            @click="loadMore"
            v-if="isLoadMoreVisible"
          >
            {{ $t('load_more') }}
          </Button>
        </div>
        <OrderDetailModal
          :orderId="selectedOrder ? selectedOrder.id : null"
        />
        <ReplicateOrderModal
          :order="selectedOrder"
          @order-replicated="getOrders"
        />
        <DestroyOrderModal
          :order="selectedOrder"
          @order-destroyed="reset"
        />
        <IssueOrderNFSeModal
          :order="selectedOrder"
          @order-nfse-issued="reset"
        />
      </section> <!-- END CONTENT -->
    </template>
  </div>
</template>

<script>

import AdvertisersService from '@/modules/advertisers/services/advertisers-service'
import { mapState } from 'vuex'
import moment from 'moment'
import OrdersService from '@/modules/orders/services/orders-service'
import variables from '@/mixins/variables'

import Button from '@/components/common/Button/Button'
import DateTimeInput from '@/components/common/Input/DateTimeInput'
import DestroyOrderModal from '@/components/orders/DestroyOrderModal'
import IssueOrderNFSeModal from '@/components/orders/IssueOrderNFSeModal'
import Loader from '@/components/common/Loader/Loader'
import OrderDetailModal from '@/components/orders/OrderDetailModal'
import ReplicateOrderModal from '@/components/orders/ReplicateOrderModal'

export default {
  name: 'OrderListTable',
  props: ['advertiser', 'orders', 'reload'],
  mixins: [variables],
  components: {
    Button,
    DateTimeInput,
    DestroyOrderModal,
    IssueOrderNFSeModal,
    Loader,
    OrderDetailModal,
    ReplicateOrderModal
  },
  data () {
    return {
      advertisers: [],
      advertisersLoader: false,
      filters: {
        advertiser_id: '',
        end: '',
        start: '',
        status: ''
      },
      isLoadMoreVisible: true,
      localOrders: [],
      loader: false,
      page: 1,
      selectedOrder: null
    }
  },
  created () {
    this.filters.start = moment({ hour: 0, minute: 0, seconds: 0 }).subtract(60, 'days').format()
    this.filters.end = moment({ hour: 0, minute: 0, seconds: 0 }).add(60, 'days').format()
    if (this.advertiser) this.filters.advertiser_id = this.advertiser.id
    if (this.orders) {
      this.localOrders = this.orders
      this.isLoadMoreVisible = false
    } else {
      if (this.user) {
        if (!this.advertiser) this.getAdvertisers()
        this.getOrders()
      }
    }
  },
  watch: {
    reload (newValue) {
      if (newValue) this.reset()
    },
    'filters.start': function (newValue, oldValue) {
      // const newV = moment(newValue)
      // const oldV = moment(oldValue)
      // if (!newV.isSame(oldV) && this.user) {
      //   this.localOrders = []
      //   this.reset()
      // }
    },
    'filters.end': function (newValue, oldValue) {
      // const newV = moment(newValue)
      // const oldV = moment(oldValue)
      // if (!newV.isSame(oldV) && this.user) {
      //   this.localOrders = []
      //   this.reset()
      // }
    },
    user (newUser) {
      this.getAdvertisers()
      this.getOrders()
    }
  },
  computed: {
    ...mapState('users', {
      user: state => state.user
    }),
    /**
     * Payment status filter
     */
    filterPaymentStatus () {
      const allowed = ['PAID', 'PENDING', 'PROCESSING', 'WAITING_PAYMENT', 'REFUSED', 'EXPIRED']
      const array = this.paymentStatus.filter(item => {
        return allowed.includes(item.key)
      })
      return array.sort((a, b) => a.value.localeCompare(b.value))
    }
  },
  methods: {
    /**
     * Get advertisers
     */
    async getAdvertisers () {
      this.advertisersLoader = true
      try {
        const advertisers = await AdvertisersService.getAdvertisers({
          companyId: this.user.company.id,
          itemsPerPage: 100
        })
        this.advertisers = advertisers.data
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.advertisersLoader = false
    },
    /**
     * Get advertisers
     */
    async getOrders () {
      if (!this.user) return false

      this.loader = true
      try {
        const orders = await OrdersService.getOrders({
          advertiserId: this.filters.advertiser_id,
          start_date: moment(this.filters.start).format('YYYY-MM-DD'),
          end_date: moment(this.filters.end).format('YYYY-MM-DD'),
          status: this.filters.status,
          page: this.page
        })
        this.localOrders = [...this.localOrders, ...orders.data]
        this.isLoadMoreVisible = orders.meta.current_page !== orders.meta.last_page
      } catch (error) {
        error.handleGlobally && error.handleGlobally()
      }
      this.loader = false
    },
    /**
     * Load more
     */
    loadMore () {
      this.page++
      this.getOrders()
    },
    /**
     * Open destroy order modal
     */
    openDestroyOrderModal (order) {
      this.selectedOrder = order
      this.$bvModal.show('destroy-order-modal')
    },
    /**
     * Open issue nfse modal
     */
    openIssueNFSeModal (order) {
      this.selectedOrder = order
      this.$bvModal.show('issue-order-nfse-modal')
    },
    /**
     * Open order detail modal
     */
    openOrderDetailModal (order) {
      this.selectedOrder = order
      this.$bvModal.show('order-detail-modal')
    },
    /**
     * Open order detail modal
     */
    openReplicateOrderModal (order) {
      this.selectedOrder = order
      this.$bvModal.show('replicate-order-modal')
    },
    /**
     * Reset and reload
     */
    reset () {
      this.page = 1
      this.localOrders = []
      this.getOrders()
    }
  }
}
</script>
