<template>
  <span>
    <template v-if="!loader">
      <button
        type="button"
        class="retry-btn"
        @click="retry"
      >
        <i class="fa fa-repeat"></i>
      </button>
    </template>
    <template v-else>
      <Loader
        :size="'small'"
        :align="'start'"
      />
    </template>
  </span>
</template>

<script>

import CustomTargetingsService from '@/modules/custom-targetings/services/custom-targetings-service'

import Loader from '@/components/common/Loader/Loader'

export default {
  name: 'RetryCustomTargetingCreationOnPlatform',
  props: ['targetingId'],
  components: {
    Loader
  },
  data () {
    return {
      loader: false
    }
  },
  methods: {
    /**
     * Retry
     */
    async retry () {
      this.loader = true
      try {
        await CustomTargetingsService.retryCreationOnPlatform(this.targetingId)
        this.$emit('retry-successful')
      } catch (error) {
        this.$emit('retry-error', error)
      }
      this.loader = false
    }
  }
}
</script>

<style lang="scss" scoped>
.retry-btn {
  padding: 0;
  margin: 0;
  background: transparent;
  border: none;
}
</style>
